import { Card, Input, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Text from 'antd/lib/typography/Text';
import { PageProps } from 'gatsby';

import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import Layout, { LayoutProps } from '../components/Layout';
import { QuickForm, QuickFormProps } from '../components/QuickForm';
import useBeFn from '../modules/app/useBeFn';
import createPrivatePage from '../utils/createPrivatePage';

function ChangePassword(props: PageProps) {
  const params = new URLSearchParams(props.location.search);
  const userId = params.get('userId');
  const [form] = useForm();
  const [updateResult, updatePassword] = useBeFn('user/updatePassword');

  useEffect(() => {
    if (updateResult.data) {
      message.success('Data saved');
      form.resetFields();
    }
  }, [updateResult.data]);

  const fields = useMemo<QuickFormProps['fields']>(() => {
    if (userId) {
      return [
        {
          label: <Text strong>New Password</Text>,
          name: 'password',
          children: <Input.Password />,
          hasFeedback: true,
          rules: [{ required: true, min: 6 }],
        },
      ];
    }
    return [
      {
        label: <Text strong>Current Password</Text>,
        name: 'currentPassword',
        children: <Input.Password />,
        rules: [{ required: true }],
      },
      {
        label: <Text strong>New Password</Text>,
        name: 'password',
        children: <Input.Password />,
        hasFeedback: true,
        rules: [{ required: true, min: 6 }],
      },
      {
        label: <Text strong>Retype New Password</Text>,
        name: 'confirmPassword',
        children: <Input.Password />,
        dependencies: ['password'],
        hasFeedback: true,
        rules: [
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error('The two passwords that you entered do not match!')
              );
            },
          }),
        ],
      },
    ];
  }, [userId]);

  const breadcrumb = useMemo<LayoutProps['breadcrumb']>(() => {
    return [
      { title: 'Home', path: '/' },
      userId
        ? { title: 'User', path: '/User/list' }
        : { title: 'Profile', path: '/profile' },
      { title: 'Change Password', path: '/change-password' },
    ];
  }, [userId]);

  const initialValues = {};

  const onFinish: QuickFormProps['onFinish'] = (values) => {
    updatePassword({
      currentPassword: values.currentPassword,
      password: values.password,
      ...(userId ? { userId } : {}),
    });
  };

  return (
    <Layout breadcrumb={breadcrumb}>
      <Helmet>
        <title>Change Password</title>
      </Helmet>
      <Card title="Change Password" loading={!initialValues}>
        {initialValues && (
          <QuickForm
            form={form}
            layout="vertical"
            name="basic"
            fields={fields}
            initialValues={initialValues}
            onFinish={onFinish}
            actionButtons={[
              {
                children: 'Submit',
                htmlType: 'submit',
                type: 'primary',
                loading: updateResult.loading,
                disabled: updateResult.loading,
              },
            ]}
          />
        )}
      </Card>
    </Layout>
  );
}

export default createPrivatePage(ChangePassword);
